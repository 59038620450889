  .feed-enter {
    opacity: 0;
  }
  .feed-enter-active {
    opacity: 100;
    transition: opacity 300ms ease-in;
  }
  .feed-exit {
    position: relative;
    
  }
  .feed-exit::after {
      content:  '';
      position: absolute;
      background: none;
      width: 100%;
      height: 100%;
  }
  .feed-exit-active {
    transition: background 100ms ease-out, transform 100ms ease-out;
  }
  .feed-exit-active::after {
    content:  '';
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 10;
    transition: background 300ms, transform 300ms;
  }
  