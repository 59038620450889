    .find-enter {
        transform: translate(100%, 0);
    }
    .find-enter-active {
        transform: translate(0, 0);
        transition: translate 300ms, transform 300ms;
    }
    .find-exit::after {
        content:  '';
        position: absolute;
        background: none;
        width: 100%;
        height: 100%;
        }
    .find-exit-active {
        transition: background 300ms, transform 300ms;
    }
    .find-exit-active::after {
        content:  '';
        position: absolute;
        background: rgba(0, 0, 0, 0.5);;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 10;
        transition: background 300ms, transform 300ms;
    }

    .left-enter {
        transform: translate(100%, 0);
    }
    .left-enter-active {
        transform: translate(0, 0);
        transition: translate 300ms, transform 300ms;
    }
    .left-exit {
        transform: translate(0, 0);
        }
    .left-exit-active {
        transition: translate 300ms, transform 300ms;
    }
    .left-exit-active {
        transform: translate(100%, 0);
        transition: translate 300ms, transform 300ms;
    }

  